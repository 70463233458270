import React, {
  useState,
  useEffect
} from 'react'

import {connect} from 'react-redux'
import {API} from 'utils'
import {
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Slider,
  Button,
  Divider,
  Typography,
  FormattedMessage,
  InputSelect,
  InputText,
  Tag
} from 'components'
import {useSelector} from 'hooks'
import {
  FormSelect,
  listHOC
} from 'common'

import {columnSize} from '../../common'
import TaxationRecordsListPageComponent from './client-financials/TaxationRecordsListPageComponent'

const radioBtnOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

function ClientFinancial({
  countriesList,
  employmentStatusList,
  sicDivisions,
  client_id,
  clientRecord,
  currentAccountID,
}) {
  const [state, setState] = useState({
    sourceOfWealthList: [],
    valueSourceOfWealthList: [],
    purposeOfAccountList: [],
    valuePurposeOfAccountList: [],
    financialInfo_expAccActivityPurpose_other: clientRecord['financialInfo_expAccActivityPurpose_other'],
    sourceOfWealthAddButtonLoading: false,
    purposeOfAccountAddButtonLoading: false,
    new_valueSourceOfWealthList: [],
    new_valuePurposeOfAccountList: [],
    new_valueSourceOfFundsList: [],
    new_selectedValueSourceOfWealthList: String(clientRecord['sourceOfWealth']).split(','),
    new_selectedValuePurposeOfAccountList: String(clientRecord['purposeOfAccount']).split(','),
    valueSourceOfFundsList: String(clientRecord['sourceOfFunds']).split(','),
    sicDivisionGroupsList: [],
    sicDivision: sicDivisions.find(e=> e.id === clientRecord.sicDivisionID)?.division || 'A',
    tfnFurtherDetails: clientRecord['tfnFurtherDetails'],
    taxationDataVisible: false,
  })
  const {
    natureOfTransactions,
    expectedTransactionsCount,
    purposeOfAccount,
    sourceOfWealth,
    noTfnReasons,
  } = useSelector(state => ({
    natureOfTransactions: state.appState.natureOfTransactions,
    expectedTransactionsCount: state.appState.expectedTransactionsCount,
    purposeOfAccount: state.appState.purposeOfAccount,
    sourceOfWealth: state.appState.sourceOfWealth,
    noTfnReasons: state.appState.noTfnReasons,
  }))

  const asyncFunction = async () => {
    try {
      const {payload: sicDivisionGroupsList} = await API.get('clients', `/get-sic-division-groups/${state.sicDivision}`)

      setState(prevState => ({
        ...prevState,
        sicDivisionGroupsList,
      }))

    } catch (error) {
      console.log('error', error)
    }
  }

  const asyncSwitchSelectionFunction = async (division) => {
    try {
      await API.get('clients', `/get-sic-division-groups/${division}`).then(
        res => {
          setState(prevState => ({
            ...prevState,
            sicDivisionGroupsList: res?.payload ? res.payload : [],
          }))
        }
      )
    } catch (e){
      console.log(e)
    }
  }

  const getAccountTransactionSelectOptions = async () => {
    try {
      await API.get('clients', `/get-account-transaction-options/${currentAccountID}`).then(
        res => {
          setState(prevState => ({
            ...prevState,
            new_valueSourceOfWealthList: res.payload.sourcesOfWealth,
            new_valuePurposeOfAccountList: res.payload.purposesOfAccount,
            new_valueSourceOfFundsList: res.payload.sourcesOfFunds,
          }))
        }
      )
    } catch (e){
      console.log(e)
    }
  }

  useEffect(() => {
    asyncFunction()
    asyncSwitchSelectionFunction(state.sicDivision)
    getAccountTransactionSelectOptions()
  }, [])

  return (
    <>
      <Row gutter={[96, 24]} justify="space-around">
        <Col {...columnSize}>
          <Card title="Employment" style={{ height: '100%' }}>
            <InputSelect
              name='employment_status_id'
              label='data_clients.employment_status'
              options={employmentStatusList}
              getOptionProps={(opt) => ({
                children: opt.file_by,
                value: opt.id
              })}
            />
            <Form.Item noStyle dependencies={['employment_status_id']}>
              {({getFieldValue}) => {
                const otherSelected = getFieldValue('employment_status_id') === 9

                return otherSelected && (
                  <InputText
                    required
                    preserve={false}
                    name='employment_status_other'
                    label='common.other'
                  />
                )
              }}
            </Form.Item>
            <Row gutter={[96, 24]} justify="space-around">
              <Col {...columnSize}>
                <Form.Item
                  label={<FormattedMessage id='data_clients.occupation' defaultMessage='Occupation' />}
                  name='occupation'
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...columnSize}>
                <Form.Item
                  label={<FormattedMessage id='data_clients.employer' defaultMessage='Employer Name' />}
                  name='employer'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[96, 24]} justify="space-around">
              <Col {...columnSize}>
                <FormSelect
                  label='Employment Classification'
                  name='sicDivisionID'
                  placeholder='Select your employment classification'
                  options={sicDivisions}
                  optionValue='id'
                  getOptionProps={({title, division}) => ({
                    children: title,
                    division: division
                  })}
                  onChange={
                    (value, options)=> {
                      setState(prevState => ({
                        ...prevState,
                        sicDivision: options.division
                      }))
                      asyncSwitchSelectionFunction(options.division)
                    }
                  }
                />
              </Col>
              <Col {...columnSize}>
                <FormSelect
                  label='Employment Industry'
                  name='sicGroupID'
                  placeholder='Select your Employment Industry'
                  options={state.sicDivisionGroupsList ? state.sicDivisionGroupsList : []}
                  optionValue='id'
                  getOptionProps={({title}) => ({children: title})}
                />
              </Col>
            </Row>
            <Row gutter={[96, 24]} justify="space-around">
              <Col {...columnSize}>
                <Form.Item
                  // label={<FormattedMessage id='data_clients.employment_industry' defaultMessage='Employment Industry' />}
                  label={'Employment Industry (old field)'}
                  name='employment_industry'
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...columnSize}>

              </Col>
            </Row>
            <Row>
              <Col {...columnSize}>
                <Form.Item
                  label={'Decline Override'}
                >
                  <Button>Staff Decline on Employment Industry</Button>
                </Form.Item>
              </Col>
              <Col {...columnSize}>

              </Col>
            </Row>
          </Card>
        </Col>
        <Col {...columnSize}>
          <Card title="Tax Information" style={{ height: '100%' }}>
            <Row gutter={[96, 24]} justify="space-around">
              <Col {...columnSize}>
                <FormSelect
                  label='Tax Country'
                  name='tax_country_id'
                  placeholder='Select your country'
                  options={countriesList}
                  optionValue='id'
                  getOptionProps={({full_name}) => ({children: full_name})}
                />
              </Col>
              <Col {...columnSize}>
                <Form.Item
                  label={<FormattedMessage id='data_clients.tax_file_number' defaultMessage='Tax File Number' />}
                  name='tax_file_number'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <InputSelect
              label='taxation.noTINReason'
              name='noTfnReasonID'
              options={noTfnReasons}
              getOptionProps={(opt) => ({
                children: `Code ${opt.code}. ${opt.reason}`,
                value: opt.id
              })}
            />

            <Form.Item
              label='TFN Further Details'
              name='tfnFurtherDetails'
              onChange = {(eventObject)=> {
                setState(prevState => ({
                  ...prevState,
                  tfnFurtherDetails: eventObject.value
                }))
              }}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='data_clients.tfn_exemption' defaultMessage='TFN Exemption' />}
              name='tfn_exemption'
            >
              <Radio.Group options={radioBtnOptions} optionType='button' buttonStyle='solid' />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='data_clients.us_citizen' defaultMessage='US Citizen' />}
              name='us_citizen'
            >
              <Radio.Group options={radioBtnOptions} optionType='button' buttonStyle='solid' />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='data_clients.us_tax_resident' defaultMessage='US Tax Resident' />}
              name='us_tax_resident'
            >
              <Radio.Group options={radioBtnOptions} optionType='button' buttonStyle='solid' />
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={[96, 24]} justify="space-around">
        <Col {...columnSize}>
          <Card title="Financials" style={{ height: '100%' }}>
            <Form.Item
              label={<FormattedMessage id='data_clients.annual_income' defaultMessage='Annual Income' />}
              name='annual_income'
            >
              <Slider
                min={0}
                max={500000}
                step={10000}
                marks={{
                  0: '0',
                  50000: '50k',
                  100000: '100k',
                  200000: '200k',
                  300000: '300k',
                  400000: '400k',
                  500000: {
                    style: {color: '#6363FF'},
                    label: <strong>500k+</strong>
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id='data_clients.net_worth' defaultMessage='Net Worth' />}
              name='net_worth'
            >
              <Slider
                min={0}
                max={3000000}
                step={10000}
                marks={{
                  0: '0',
                  250000: '250K',
                  500000: '500K',
                  1000000: '1M',
                  1500000: '1.5M',
                  2000000: '2.0M',
                  2500000: '2.5M',
                  3000000: {
                    style: {color: '#6363FF'},
                    label: <strong>3M+</strong>
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage
                  id='data_clients.intended_deposit'
                  defaultMessage='Intended deposit over the next 12 months (USD)'
                />
              }
              name='intended_deposit'
            >
              <Slider
                min={0}
                max={500000}
                step={10000}
                marks={{
                  0: '0',
                  50000: '50k',
                  100000: '100k',
                  200000: '200k',
                  300000: '300k',
                  400000: '400k',
                  500000: {
                    style: {color: '#6363FF'},
                    label: <strong>500k+</strong>
                  }
                }}
              />
            </Form.Item>
          </Card>
        </Col>
        <Col {...columnSize} >
          <TaxationRecordsListPageComponent
            client_id={client_id}
            noTINReasons={state.noTFNreasonsList}
          />
        </Col>
 
        <Col {...columnSize}>
          <Card title="Source of Wealth" style={{ height: '100%' }}>
            {clientRecord.financialInfo_sourceOfWealth?.length ? (
              <>
                <Typography.Paragraph>
                  <FormattedMessage id='client.financial.sourceOfWealthLegacy' />
                </Typography.Paragraph>
                <Row style={{marginBotton: 14}}>
                  {sourceOfWealth.map(item => {
                    if (clientRecord.financialInfo_sourceOfWealth.includes(item.id)) {
                      return <Tag>{item.fileBy}</Tag>
                    }
                    return null
                  })}
                </Row>
                <Divider/>
              </>
            ) : null}
            <InputSelect
              mode='multiple'
              label='client.financial.sourceOfWealthNew'
              name='sourceOfWealth'
              options={state.new_valueSourceOfWealthList}
              getOptionProps={(opt) => ({
                value: opt.id,
                children: opt.title
              })}
            />
          </Card>
        </Col>
        <Col {...columnSize}>
          <Card title="Source of Funds" style={{ height: '100%' }}>
            <InputSelect
              mode='multiple'
              label='client.financial.sourceOfFunds'
              name='sourceOfFunds'
              options={state.new_valueSourceOfFundsList}
              getOptionProps={(opt) => ({
                value: opt.id,
                children: opt.title
              })}
            />
          </Card>
        </Col>
        <Col {...columnSize}>
          <Card title="Purpose of Account" style={{ height: '100%' }}>
            {clientRecord.financialInfo_purposeOfAccount?.length ? (
              <>
                <Typography.Paragraph>
                  <FormattedMessage id='client.financial.purposeOfAccountLegacy' />
                </Typography.Paragraph>
                <Row style={{marginBotton: 14}}>
                  {purposeOfAccount.map(item => {
                    if (clientRecord.financialInfo_purposeOfAccount.includes(item.id)) {
                      return <Tag>{item.fileBy}</Tag>
                    }
                    return null
                  })}
                </Row>
                <Divider/>
              </>
            ) : null}
            <InputSelect
              mode='multiple'
              label='client.financial.purposeOfAccountNew'
              name='purposeOfAccount'
              options={state.new_valuePurposeOfAccountList}
              getOptionProps={(opt) => ({
                value: opt.id,
                children: opt.title
              })}
            />
          </Card>
        </Col>
        <Col {...columnSize}>
          <Card title="Account Activity" style={{ height: '100%' }}>

            <Row gutter={[24,0]} justify="space-around">
              <Col xs={12}>
                <InputSelect
                  name='financialInfo_expAccActivityCount'
                  label='client.financial.expectedAccountPurpose'
                  options={expectedTransactionsCount}
                  getOptionProps={opt => ({
                    value: opt.id,
                    children: opt.name
                  })}
                />
              </Col>
              <Col xs={12}>
                <InputSelect
                  name='financialInfo_expAccActivityPurpose'
                  label='client.financial.expectedAccountPurpose'
                  options={natureOfTransactions}
                  getOptionProps={opt => ({
                    value: opt.id,
                    children: opt.name
                  })}
                />
              </Col>
            </Row>
            <Form.Item noStyle dependencies={['financialInfo_expAccActivityPurpose']}>
              {({getFieldValue}) => {
                const value = getFieldValue('financialInfo_expAccActivityPurpose')

                return value === 4 && (
                  <InputText
                    required
                    preserve={false}
                    name='financialInfo_expAccActivityPurpose_other'
                    label='common.other'
                  />
                )
              }}
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = state => {
  return {currentAccountID: state.appState.currentAccountRecord.id}
}

export default connect(mapStateToProps, {})(listHOC(ClientFinancial))