import * as React from 'react'
import {
  ContainerOutlined,
  ControlOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
  NavLink,
  Menu,
  SolutionOutlined,
  IdcardOutlined,
  TeamOutlined
} from 'components'
import {routes} from 'configs'
import {
  useSelector,
  useQueryParams
} from 'hooks'
import { createUseStyles } from 'react-jss'
import {
  Account,
  Staff
} from 'types'

const useStyles = createUseStyles({
  menu: {
    overflow: 'auto',
    height: '100%',
  },
})

// TODO Refactor

const MenuComponent: React.FC = () => {
  const classes = useStyles()
  const [params] = useQueryParams()
  const [state, setState] = React.useState<{
    openKeys: string[];
    selectedKeys: string[];
  }>({
    openKeys: [],
    selectedKeys: ['/dashboard']
  })
  const {
    currentStaffRecord, currentUserBeta, currentAccountRecord
  } = useSelector(state => ({
    currentStaffRecord: state.appState.currentStaffRecord as Staff,
    currentUserBeta: state.appState.currentUserRecord?.beta,
    currentAccountRecord: state.appState.currentAccountRecord as Account
  }))

  const onMenuOpenChange = (openKeys: any[]) => {
    setState({
      ...state,
      openKeys: [openKeys[openKeys.length - 1]]
    })
  }

  return (
    <Menu
      theme='dark'
      mode='inline'
      className={classes.menu}
      openKeys={state.openKeys}
      selectedKeys={state.selectedKeys}
      onOpenChange={onMenuOpenChange}
      items={[
        {
          key: routes.dashboard,
          icon: <DashboardOutlined />,
          title: 'Dashboard',
          label: <NavLink to={routes.dashboard}>Dashboard</NavLink>
        },
        {
          key: routes.organiser,
          title: 'Organiser',
          label: 'Organiser',
          icon: <SolutionOutlined />,
          children: [
            {
              key: routes.organiserTaskList,
              label: <NavLink to={routes.organiserTaskList}>All tasks</NavLink>
            },
            {
              key: routes.organiserMyTasks,
              label: <NavLink to={routes.organiserMyTasks}>My tasks</NavLink>
            },
          ]
        },
        {
          key: routes.clients,
          title: 'Clients',
          label: 'Clients',
          icon: <TeamOutlined />,
          children: [
            {
              key: routes.clientsListWithParam(3),
              label: (
                <NavLink
                  to={routes.clientsListWithParam(3)}
                >
                  List
                </NavLink>
              )
            },
            {
              key: routes.clientsListWithParam(2),
              label: <NavLink to={routes.clientsListWithParam(2)}>Leads</NavLink>
            },
            {
              key: routes.clientsListWithParam(1),
              label: <NavLink to={routes.clientsListWithParam(1)}>Opportunities</NavLink>
            },
            {
              key: routes.clientsNew,
              label: <NavLink to={routes.clientsNew}>New Record</NavLink>
            },
            {
              key: routes.clientsWatching,
              label: <NavLink to={routes.clientsWatching}>Watching</NavLink>
            },
            {
              key: routes.clientsFinancialAccounts,
              label: <NavLink to={routes.clientsFinancialAccounts}>Financial Accounts</NavLink>
            },
            ...(!currentAccountRecord.feature_xeondaClientPortal ? [] : [{
              key: routes.clientsPortalUsers,
              label: <NavLink to={routes.clientsPortalUsers}>Portal Users</NavLink>
            }]),
            {
              key: routes.clientsInformationRequests,
              label: <NavLink to={routes.clientsInformationRequests}>Information Requests</NavLink>
            },
            ...(!currentUserBeta ? [] : [{
              key: routes.clientsAccountClosures,
              label: <NavLink to={routes.clientsAccountClosures}>[B] Account Closures</NavLink>
            }]),
            {
              key: routes.clientsNewBulk,
              label: <NavLink to={routes.clientsNewBulk}>New Record (Bulk)</NavLink>
            },
            {
              key: routes.clientsNewCsv,
              label: <NavLink to={routes.clientsNewCsv}>New Record (CSV)</NavLink>
            },
          ]
        },
        {
          key: routes.tradingCommission,
          title: 'Trading Commission',
          label: 'Trading Commission',
          icon: <SolutionOutlined />,
          children: [
            {
              key: routes.tradingCommissionClients,
              label: <NavLink to={routes.tradingCommissionClients}>Clients</NavLink>
            },
            {
              key: routes.tradingCommissionWallets,
              label: <NavLink to={routes.tradingCommissionWallets}>Wallets</NavLink>
            },
            {
              key: routes.tradingCommissionWalletsTransactions,
              label: <NavLink to={routes.tradingCommissionWalletsTransactions}>Wallets Transactions</NavLink>
            },
          ]
        },
        {
          key: routes.documents,
          title: 'Documents',
          label: 'Documents',
          icon: <ContainerOutlined />,
          children: [
            {
              key: routes.documentsNew,
              label: <NavLink to={routes.documentsNew}>New Document</NavLink>
            },
            {
              key: routes.documentsWordTemplates,
              label: <NavLink to={routes.documentsWordTemplates}>Word Templates</NavLink>
            },
            {
              key: routes.documentsWordTemplatesNew,
              label: <NavLink to={routes.documentsWordTemplatesNew}>New Word Templates</NavLink>
            },
            {
              key: routes.documentsDigiSignTemplates,
              label: <NavLink to={routes.documentsDigiSignTemplates}>DigiSign Templates</NavLink>
            },
            {
              key: routes.documentsEmailTemplates,
              label: <NavLink to={routes.documentsEmailTemplates}>Email Templates</NavLink>
            },
            {
              key: routes.documentsEmailCsv,
              label: <NavLink to={routes.documentsEmailCsv}>Email Send from CSV</NavLink>
            },
          ]
        },
        {
          key: routes.agents,
          title: 'Agents',
          label: 'Agents',
          icon: <ContainerOutlined />,
          children: [
            {
              key: routes.agentsList,
              label: <NavLink to={routes.agentsList}>List</NavLink>
            }
          ]
        },
        {
          key: routes.teams,
          title: 'Teams',
          label: 'Teams',
          icon: <ContainerOutlined />,
          children: [
            {
              key: routes.teamsList,
              label: <NavLink to={routes.teamsList}>List</NavLink>
            },
            {
              key: routes.teamsNew,
              label: <NavLink to={routes.teamsNew}>New</NavLink>
            },
          ]
        },
        {
          key: routes.staff,
          title: 'Staff',
          label: 'Staff',
          icon: <ContainerOutlined />,
          children: [
            {
              key: routes.staffList,
              label: <NavLink to={routes.staffList}>List</NavLink>
            },
            {
              key: routes.staffNew,
              label: <NavLink to={routes.staffNew}>New</NavLink>
            },
          ]
        },
        {
          key: routes.trading,
          title: 'Trading',
          label: 'Trading',
          icon: <LineChartOutlined />,
          children: [
            {
              key: routes.tradingCommissionsFull,
              label: <NavLink to={routes.tradingCommissionsFull}>Commissions Full</NavLink>
            },
            {
              key: routes.tradingCommissionsSum,
              label: <NavLink to={routes.tradingCommissionsSum}>Commissions Sum</NavLink>
            },
            {
              key: routes.tradingTransactions,
              label: <NavLink to={routes.tradingTransactions}>Commissions Transactions</NavLink>
            },
            {
              key: routes.tradingAccounts,
              label: <NavLink to={routes.tradingAccounts}>Trading Accounts</NavLink>
            },
            {
              key: routes.tradingTransactions2,
              label: <NavLink to={routes.tradingTransactions2}>Transactions</NavLink>
            },
          ]
        },
        {
          key: routes.financial,
          title: 'Financial',
          label: 'Financial',
          icon: <DollarCircleOutlined />,
          children: [
            {
              key: routes.financialAccountFinancialAccounts,
              label: <NavLink to={routes.financialAccountFinancialAccounts}>Accounts</NavLink>
            },
          ]
        },
        {
          key: routes.verifications,
          title: 'Verifications',
          label: 'Verifications',
          icon: <IdcardOutlined />,
          children: [
            {
              key: routes.verificationsNew(),
              title: 'IDV New',
              label: <NavLink to={routes.verificationsNew()}>IDV New</NavLink>
            }
          ]
        },
        {
          key: routes.identification,
          title: 'Identification',
          label: 'Identification',
          icon: <SolutionOutlined />,
          children: [
            {
              key: routes.identificationNew,
              label: <NavLink to={routes.identificationNew}>New</NavLink>
            },
            {
              key: routes.identificationList,
              label: <NavLink to={routes.identificationList}>List</NavLink>
            },
          ]
        },
        ...(!currentStaffRecord.security_finance_canViewAccountFinancialReports ? [] : [{
          key: routes.reports,
          title: 'Reports',
          label: 'Reports',
          icon: <DashboardOutlined />,
          children: [
            {
              key: routes.reportsDashboard,
              label: <NavLink to={routes.reportsDashboard}>Dashboard</NavLink>
            }
          ]
        }]),
        {
          key: routes.settings,
          title: 'Settings',
          label: 'Settings',
          icon: <ControlOutlined />,
          children: [
            {
              key: routes.settingsAccount,
              label: <NavLink to={routes.settingsAccount}>Account</NavLink>
            },
            {
              key: routes.settingsDocCheckCollections,
              label: <NavLink to={routes.settingsDocCheckCollections}>Document Checklists</NavLink>
            },
            {
              key: routes.settingsTradingPlatforms,
              label: <NavLink to={routes.settingsTradingPlatforms}>Trading Platforms</NavLink>
            },
            {
              key: routes.settingsUserProfile,
              label: <NavLink to={routes.settingsUserProfile}>User Profile</NavLink>
            },
            {
              key: routes.settingsCurrencies,
              label: <NavLink to={routes.settingsCurrencies}>Currencies</NavLink>
            },
            {
              key: routes.settingsJournals,
              label: <NavLink to={routes.settingsJournals}>Journals</NavLink>
            },
            {
              key: routes.settingsSystemTools,
              label: <NavLink to={routes.settingsSystemTools}>System Tools</NavLink>
            },
          ]
        },
        {
          key: routes.backoffice,
          title: 'Backoffice',
          label: 'Backoffice',
          icon: <ControlOutlined />,
          children: [
            {
              key: routes.backofficeAccountsList,
              label: <NavLink to={routes.backofficeAccountsList}>Accounts</NavLink>
            },
            {
              key: routes.backofficeXeondaIDsList,
              label: <NavLink to={routes.backofficeXeondaIDsList}>Xeonda Record IDs</NavLink>
            },
            {
              key: routes.backofficeFieldUpdate,
              label: <NavLink to={routes.backofficeFieldUpdate}>Field Update</NavLink>
            }
          ]
        }
      ]}
    />
  )
}

export default MenuComponent
