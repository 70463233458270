import React from 'react'

import {
  Row,
  Col,
  Form,
  ExtendedDescriptions,
  SubmitButton,
  Divider,
  Table
} from 'components'
import {useSelector} from 'hooks'
import {
  SectionTitle,
  InteractiveColumn
} from '../shared'
import {Types} from '../../duck'

const FORM_NAME = 'contact'

interface ContactProps extends Types.CommonProps {
  thirdParty?: boolean;
}

const Contact: React.FC<ContactProps> = ({
  eApplicationFormData,
  thirdParty,
  clientRecord,
  updateClientRecord,
  submitting
}) => {
  const {streetTypes, countriesList} = useSelector(state => ({
    streetTypes: state.appState.streetCodes.streetType,
    countriesList: state.appState.countriesList
  }))
  const loading = submitting.includes(FORM_NAME)

  return (
    <Form
      name={FORM_NAME}
      layout='vertical'
      initialValues={eApplicationFormData}
    >
      <Row gutter={[24, 0]}>
        <Col xs={24}>

          <SectionTitle>Contact</SectionTitle>
          {!thirdParty ?
            <>
              <Table<Types.InteractiveTableColumn>
                pagination={false}
                columns={[
                  {
                    title: 'Item',
                    dataIndex: 'item',
                  },
                  {
                    title: 'New Data (Info Request)',
                    dataIndex: 'clientFieldName',
                    render: (key, {clientFieldName, formFieldName}) => {
                      const props = {
                        clientFieldName,
                        formFieldName,
                        updateClientRecord,
                        clientRecord,
                        loading
                      }

                      if (key === 'streetStreetTypeId') {
                        return (
                          <InteractiveColumn
                            {...props}
                            inputType='select'
                            options={streetTypes}
                            getOptionProps={opt => ({
                              value: opt.id,
                              children: opt.fileBy
                            })}
                          />
                        )
                      }

                      if (key === 'streetCountryId') {
                        return (
                          <InteractiveColumn
                            {...props}
                            inputType='select'
                            options={countriesList}
                            getOptionProps={opt => ({
                              value: opt.id,
                              children: opt.full_name
                            })}
                          />
                        )
                      }

                      return (
                        <InteractiveColumn {...props} />
                      )
                    }
                  },
                  {
                    title: 'Current Client',
                    dataIndex: 'clientFieldName',
                    render: (key, {clientFieldName}) => {
                      const value = clientRecord[clientFieldName]

                      if (key === 'streetStreetTypeId') {
                        return streetTypes.find(c => c.id === value)?.fileBy
                      }

                      if (key === 'streetCountryId') {
                        return countriesList.find(c => c.id === value)?.full_name
                      }

                      return value
                    }
                  },
                ]}
                dataSource={[
                  {
                    item: 'Tel (Home)',
                    clientFieldName: 'tel_home',
                    formFieldName: ['phone', 'home'],
                  },
                  {
                    item: 'Tel (Work)',
                    clientFieldName: 'tel_work',
                    formFieldName: ['phone', 'work'],
                  },
                  {
                    item: 'Tel (Mobile)',
                    clientFieldName: 'tel_mobile',
                    formFieldName: ['phone', 'mobile'],
                  },
                  {
                    item: 'Unit Number',
                    clientFieldName: 'streetUnitNumber',
                    formFieldName: ['streetAddress', 'unitNumber'],
                  },
                  {
                    item: 'Street Number',
                    clientFieldName: 'streetStreetNumber',
                    formFieldName: ['streetAddress', 'streetNumber'],
                  },
                  {
                    item: 'Street Name',
                    clientFieldName: 'streetStreetName',
                    formFieldName: ['streetAddress', 'streetName'],
                  },
                  {
                    item: 'Street Type',
                    clientFieldName: 'streetStreetTypeId',
                    formFieldName: ['streetAddress', 'streetTypeId'],
                  },
                  {
                    item: 'Suburb',
                    clientFieldName: 'streetSuburb',
                    formFieldName: ['streetAddress', 'suburb'],
                  },
                  {
                    item: 'State',
                    clientFieldName: 'streetState',
                    formFieldName: ['streetAddress', 'state'],
                  },
                  {
                    item: 'Postcode',
                    clientFieldName: 'streetPostcode',
                    formFieldName: ['streetAddress', 'postcode'],
                  },
                  {
                    item: 'Country',
                    clientFieldName: 'streetCountryId',
                    formFieldName: ['streetAddress', 'countryId'],
                  },
                ]}
              />
              <SubmitButton loading={loading} />
              <Divider />
            </>
            : (
              <ExtendedDescriptions
                items={[
                  {
                    label: 'Tel (Home)',
                    translate: false,
                    value: eApplicationFormData?.phone?.home,
                  },
                  {
                    label: 'Tel (Work)',
                    translate: false,
                    value: eApplicationFormData?.phone?.work,
                  },
                  {
                    label: 'Tel (Mobile)',
                    translate: false,
                    value: eApplicationFormData?.phone?.mobile,
                  },
                  {
                    label: 'Street Name',
                    translate: false,
                    value: eApplicationFormData?.streetAddress?.streetName,
                  },
                  {
                    label: 'Street Type',
                    translate: false,
                    value: eApplicationFormData?.streetAddress?.streetType,
                  },
                  {
                    label: 'State',
                    translate: false,
                    value: eApplicationFormData?.streetAddress?.state,
                  },
                  {
                    label: 'Postcode',
                    translate: false,
                    value: eApplicationFormData?.streetAddress?.postcode,
                  },
                  {
                    label: 'Country',
                    translate: false,
                    value: eApplicationFormData?.streetAddress?.country,
                  },
                ]}
              />
            )}
        </Col>
      </Row>
    </Form>
  )
}

export default Contact
