import React from 'react'

import {
  Checkbox,
  ExtendedDescriptions
} from 'components'

interface ComplianceProps {
  eApplicationFormData: any;
}

const Compliance: React.FC<ComplianceProps> = ({eApplicationFormData}) => {

  return (
    <>
      <ExtendedDescriptions
        title='compliance.title'
        items={[
          {
            label: 'Public Office Or Pep',
            translate: false,
            value: <Checkbox checked={Boolean(eApplicationFormData?.publicOfficeOrPep)} />,
          },
          {
            label: 'Family Public Office Or Pep',
            translate: false,
            value: <Checkbox checked={Boolean(eApplicationFormData?.familyPublicOfficeOrPep)} />,
          },
          {
            label: 'Aml Or Cft',
            translate: false,
            value: <Checkbox checked={Boolean(eApplicationFormData?.amlOrCft)} />,
          },
          {
            label: 'Adverse Information',
            translate: false,
            value: <Checkbox checked={Boolean(eApplicationFormData?.adverseInformation)} />,
          },
          {
            label: 'Refused Banking',
            translate: false,
            value: <Checkbox checked={Boolean(eApplicationFormData?.refusedBanking)} />,
          },
        ]}
      />
    </>
  )
}

export default Compliance
