import {
  Table,
  Button,
  Card,
  Drawer,
  Tag,
  notification,
  Divider,
  Popconfirm
} from 'antd'
import React, {
  useState,
  useCallback
} from 'react'
import { useDispatch } from 'react-redux'
import {
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { useParams } from 'react-router-dom'

import {clientsActions} from 'configs/ducks/clients'
import {ModalView} from '../../../../common/ModalView'
import ClientCreateFinancialAccount from './ClientCreateFinancialAccount'
import {useAsyncFn} from 'react-use'
import {API} from 'aws-amplify'

export const ClientFinancialAccount = ({financialAccount}) => {
  const { client_id } = useParams()
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState({})
  const dispatch = useDispatch()
  const handle_create_new = () => {
    setIsModalVisible(true)
  }

  const handleSaveSuccess = () => {
    setIsModalVisible(false)
    dispatch(clientsActions.financialAccountAction(client_id))
  }

  const showSelectedAccount = useCallback((value) => {
    setVisibleDrawer(true)
    setSelectedAccount(value)
  },[])

  const onCloseDrawer = () => {
    setVisibleDrawer(false)
  }

  const onUpdateSuccess = (values) => {
    setVisibleDrawer(false)
    dispatch(clientsActions.updateFinancialAccountAccount({
      ...selectedAccount,
      ...values,
    }))
  }

  const [updateState, update] = useAsyncFn(async (id) => {
    try {
      if (client_id) {
        await API.put('financial-accounts', `/delete/${id}`, {body: {}})
        dispatch(clientsActions.financialAccountAction(client_id))
      }
    } catch (error) {
      notification.error({message: error.message})
    }
  })

  const documentColumns = [
    {
      title: 'Xeonda ID',
      dataIndex: 'id'
    },
    {
      title: 'Type',
      dataIndex: 'financialAccountType',
      render: (value) => {
        return <Tag color={value === 1 ? 'blue' : value === 2 ? 'gold' : 'volcano'}>{value === 1 ? 'Bank Account' : value === 2 ? 'Crypto Wallet' : 'GAT Money Wallet'}</Tag>
      }
    },
    {
      title: 'Name',
      dataIndex: 'fileBy'
    },
    {
      title: 'Record Created',
      dataIndex: 'recordCreated',
      render: text => {
        return (
          <span>
            {text
              .toString()
              .substring(0, text.length - 5)
              .split('T')
              .join(' ')}
          </span>
        )
      }
    },
    {
      title: 'Actions',
      render: (_, raw) => {
        return (
          <>
            <Button type="link" onClick={() => showSelectedAccount(raw)} icon={<EditOutlined />} />
            <Divider type='vertical' />
            <Popconfirm
              title='Are you sure to delete this record?'
              onConfirm={() => update(raw.id)}
              okText='Yes'
              onCancel='No'
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          </>
        )
      }
    }
  ]

  return (
    <>
      <Card
        title='Financial Accounts'
        extra={
          [
            <Button key='2' onClick={handle_create_new}>
          Create New
            </Button>,
          ]}
      >
        <Table
          rowKey='id'
          style={{width: '100%'}}
          scroll={{x: true}}
          columns={documentColumns}
          dataSource={financialAccount}
        />
      </Card>
      <ModalView
        title={'Create Financial Account'}
        isModalVisible={isModalVisible}
        footer={null}
        handleCancel={() => setIsModalVisible(false)}
        body={<ClientCreateFinancialAccount onSave={handleSaveSuccess} />}
      />
      <Drawer
        title={`Financial Account [${selectedAccount?.id}]`}
        placement="right"
        width={500}
        onClose={onCloseDrawer}
        visible={visibleDrawer}
       
      >
        <ClientCreateFinancialAccount data={selectedAccount} onSave={onUpdateSuccess} />
      </Drawer>
    </>
  )
}
