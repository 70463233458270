import React, {
  useState,
  useEffect,
} from 'react'

import {
  Layout,
  Route,
  Redirect,
  RouterSwitch,
  Image
} from 'components'
import {
  useHistory,
  useSelector
} from 'hooks'

import {routes} from 'configs'

import {constants} from './duck'

import * as LC from './components'

import logo from 'logo.png'

import * as ReactGA from 'react-ga'

import {createUseStyles} from 'react-jss'

import moment from 'moment'
import {
  accountLogos,
  logoDefault
} from '../assets'

const useStyles = createUseStyles({
  root: {
    background: '#fff',
    height: '100%',
    overflow: 'hidden',
  },
  logo: {padding: '12px 16px'},
  sider: {
    '& .ant-layout-sider-children': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .ant-layout-sider-trigger': {
      position: 'static',
      borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06)',
    },
  },
  content: {
    background: '#fff',
    overflowY: 'auto',
  },
})

ReactGA.initialize('UA-181203507-2')

const LayoutComponent = ({appRemountKey}) => {
  const [state, setState] = useState({
    collapsed: false,
    logo: logo,
  })

  // props.location.pathname.includes('/reports') && adminStaff.security_finance_canViewAccountFinancialReports !== 1

  const {
    account, authenticatedUser, currentStaffRecord
  } = useSelector(state => ({
    account: state.appState.currentAccountRecord,
    authenticatedUser: state.appState.authenticatedUser,
    currentStaffRecord: state.appState.currentStaffRecord,
  }))

  const history = useHistory()
  const hideReports = currentStaffRecord.security_finance_canViewAccountFinancialReports !== 1

  useEffect(() => {
    history.listen(location => {
      ReactGA.set({page: location.pathname})
      ReactGA.pageview(location.pathname)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, ReactGA])

  const classes = useStyles()

  return (
    <Layout className={classes.root} key={appRemountKey}>
      <Layout.Sider
        collapsible
        className={classes.sider}
        collapsed={state.collapsed}
        onCollapse={collapsed => {
          setState(prevState => ({
            ...prevState,
            collapsed
          }))
        }}
      >
        <Image
          src={(account?.id && accountLogos[account.id]) || logoDefault}
          className={classes.logo}
          alt='Logo'
        />
        {authenticatedUser && <LC.Menu />}
      </Layout.Sider>
      <Layout className={classes.root}>
        <LC.Header />
        <Layout.Content className={classes.content}>
          <RouterSwitch>
            <LC.UnauthenticatedRoute
              authenticated={authenticatedUser}
              path={routes.login}
            >
              <LC.Login />
            </LC.UnauthenticatedRoute>
            {constants.ROUTES.map((item) => {
              if (item.path.includes('/reports') && hideReports) {
                return null
              }

              return <LC.AuthenticatedRoute key={item.path} {...item} authenticated={authenticatedUser} />
            })}
            <Route exact path="/">
              <Redirect to={authenticatedUser ? routes.dashboard : routes.login} />
            </Route>

            <Route path="*">
              <LC.CatchAll />
            </Route>
          </RouterSwitch>
        </Layout.Content>
        <Layout.Footer style={{textAlign: 'center'}}>
          <strong>{account.account_name || 'Xeonda Admin Portal'}</strong> ©{moment().format('yyyy')}
          <br />
          <small>
            Created by&nbsp;
            <a href='https://deliostech.com' target='_blank' rel='noreferrer'>
              Delios Technologies
            </a>
            {' '}-{' '}
            <strong>
              Version 0.30.0
            </strong>
          </small>
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}

export default LayoutComponent

