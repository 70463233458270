import * as React from 'react'
import {
  Button,
  Row,
  ArrowRightOutlined,
  CloseOutlined,
  InputText,
  InputDatePicker,
  InputSelect,
  InputGroup
} from 'components'
import {createUseStyles} from 'react-jss'
import {
  useFormInstance,
  usePrevious,
  useIntl,
  useWatch
} from 'hooks'
import {Types} from '../../../duck'
import {FormRule} from 'antd'

const useStyles = createUseStyles({
  button: {flexBasis: 50},
  formItem: {
    marginBottom: 0,
    width: '100%'
  }
})

const InteractiveColumn: React.FC<Types.InteractiveColumnProps<any>> = (
  {
    options,
    getOptionProps,
    inputType = 'input' ,
    loading,
    clientRecord,
    clientFieldName,
    formFieldName,
    updateClientRecord
  }
) => {
  const intl = useIntl()
  const prevSubmitting = usePrevious(loading)
  const form = useFormInstance()
  const value = useWatch(formFieldName, form)
  const classes = useStyles()
  const [updated, updateValue] = React.useState(false)
  const prevUpdated = usePrevious(updated)
  const memoizedClientValue = React.useMemo(() => clientRecord[clientFieldName], [loading])
  const initialValue = React.useMemo(() => form.getFieldValue(formFieldName), [])

  React.useEffect(() => {
    const value = form.getFieldValue(formFieldName)

    updateClientRecord({[clientFieldName]: updated ? value : memoizedClientValue})
  }, [updated])

  React.useEffect(() => {
    // if a user updated the value and types in smth in the same field
    if (updated && prevUpdated) {
      updateClientRecord({[clientFieldName]: memoizedClientValue})

      updateValue(false)
    }
  }, [value, updated, memoizedClientValue])
  
  React.useEffect(() => {
    if (!loading && prevSubmitting) {
      updateValue(false)
    }
  }, [loading])

  const onClick = () => {
    updateValue(!updated)
  }

  const inputProps = {
    name: formFieldName,
    formItemClassName: classes.formItem,
    status: initialValue !== memoizedClientValue ? 'warning' : undefined,
    rules: [
      (form) => ({
        validator: (rule, value) => {
          const touched = form.isFieldTouched(formFieldName)

          return touched && !value && updated && inputType !== 'group'
            ? Promise.reject(intl.formatMessage({ id: 'validation.required' }))
            : Promise.resolve()
        }
      }),
    ] as FormRule[]
  } as const

  let input = <InputText {...inputProps} />

  if (inputType === 'date') {
    input = <InputDatePicker label={null} {...inputProps} />
  }

  if (inputType === 'select' && getOptionProps) {
    input = (
      <InputSelect
        {...inputProps}
        className={classes.formItem}
        options={options}
        getOptionProps={getOptionProps}
      />
    )
  }

  if (inputType === 'group') {
    input = <InputGroup {...inputProps} />
  }

  return (
    <Row wrap={false}>
      {input}
      <Button
        htmlType='button'
        type="primary"
        danger={updated}
        icon={updated ? <CloseOutlined /> : <ArrowRightOutlined />}
        className={classes.button}
        onClick={onClick}
      />
    </Row>
  )
}

export default InteractiveColumn
