import React, {
  useEffect,
  useState
} from 'react'

import {API} from 'utils'
import {
  FULL_DATE_TIME_FORMAT,
  COMMON_COL_PROPS
} from 'consts'
import moment from 'moment'
import {ViewDocumentEmail} from 'common'
import {FilesTable} from './components'
import {useSelector} from 'hooks'

import {
  Row,
  Col,
  Button,
  Form,
  Skeleton,
  Empty,
  Descriptions,
  notification,
  InputSelect,
  ClientsSelect,
  InputTextArea
} from 'components'

import {
  Document,
  DocumentEmail,
  DocumentFile,
  RouteComponentProps
} from 'types'

const DocumentSummary: React.FC<RouteComponentProps<{id: string;}>> = ({match: {params: {id}}}) => {
  const accounts = useSelector(state => state.appState.accountsList)
  const [form] = Form.useForm()
  const [state, setState] = useState<{
    fetching: boolean;
    saving: boolean;
    document: Document | null;
    emails: DocumentEmail[];
    files: DocumentFile[];
  }>({
    fetching: false,
    saving: false,
    document: null,
    emails: [],
    files: []
  })

  useEffect(() => {
    const fn = async () => {
      try {
        setState(prevState => ({
          ...prevState,
          fetching: true
        }))

        const {
          document, emails, files
        } = await API.get('documents', `/${id}`)

        setState(prevState => ({
          ...prevState,
          fetching: false,
          document,
          emails,
          files
        }))
      } catch (e: any) {
        notification.error({message: e?.response?.data?.error || e.message})

        setState(prevState => ({
          ...prevState,
          fetching: false
        }))
      }
    }

    fn()
  }, [id])

  if (state.fetching) {
    return <Skeleton active />
  }

  if (!state.document) {
    return <Empty />
  }

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={state.document}
      onFinish={async values => {
        try {
          setState(prevState => ({
            ...prevState,
            saving: true
          }))

          await API.put('documents', `/update/${state.document?.id as number}`, {body: values})

          notification.success({message: 'Done'})

          setState(prevState => ({
            ...prevState,
            saving: false
          }))
        } catch (e) {
          setState(prevState => ({
            ...prevState,
            saving: false
          }))
        }
      }}
    >
      <Row gutter={[48, 0]}>
        <Col {...COMMON_COL_PROPS}>
          <InputSelect
            required
            label='common.account'
            name='account_id'
            options={accounts}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.account_name,
            })}
          />
          <ClientsSelect
            required
            name='client_id'
          />
          <InputTextArea
            required
            name='description'
            label='common.shortDescription'
            autoSize={{
              minRows: 2,
              maxRows: 2
            }}
          />
          <InputTextArea
            name='long_description'
            label='common.longDescription'
            autoSize={{
              minRows: 2,
              maxRows: 2
            }}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Descriptions
            bordered
            layout='vertical'
            size='small'
            style={{marginBottom: 24}}
            column={{
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2
            }}
          >
            <Descriptions.Item label='ID'>
              <strong>{state.document.id}</strong>
            </Descriptions.Item>
            <Descriptions.Item label='Record Created:'>
              <strong>{moment(state.document.record_created).format(FULL_DATE_TIME_FORMAT)}</strong>
            </Descriptions.Item>
          </Descriptions>
          {
            state.document.document_type_id === 6
              ? <ViewDocumentEmail document={state.document} email={state.emails} />
              : <FilesTable fileList={state.files} eafDocument={state.document.document_type_id === 8} />
          }
        </Col>
      </Row>
      <Row justify='end'>
        <Button
          type='primary'
          htmlType='submit'
          loading={state.saving}
        >
          Save Record
        </Button>
      </Row>
    </Form>
  )
}

export default DocumentSummary
