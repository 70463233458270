import * as React from 'react'
import {useSelector} from 'hooks'
import {Table} from 'components'
import {SectionTitle} from '../../../shared'

interface IndustrialClassificationProps {
  sicDivisionID: number | undefined;
  sicGroupID: number | undefined;
}

const IndustrialClassification: React.FC<IndustrialClassificationProps> = ({sicDivisionID, sicGroupID}) => {
  const {
    sicGroups,
    sicDivisions,
  } = useSelector(state => ({
    sicDivisions: state.appState.sicDivisions,
    sicGroups: state.appState.sicGroups,
  }))

  return (
    <>
      <SectionTitle>
        Standard Industrial Classification
      </SectionTitle>
      <Table
        pagination={false}
        dataSource={[
          {
            sicDivisionID,
            sicGroupID
          },
        ]}
        columns={[
          {
            title: 'Sic Division',
            dataIndex: 'sicDivisionID',
            render: (field) => sicDivisions.find(item => item.id === field)?.title
          },
          {
            title: 'Sic Group',
            dataIndex: 'sicGroupID',
            render: (field) => sicGroups.find(item => item.id === field)?.title
          }
        ]}
      />
    </>
  )
}

export default IndustrialClassification
