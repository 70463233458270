import React, {
  useState,
  useEffect
} from 'react'
import {
  useSelector,
  connect
} from 'react-redux'
import {API} from 'aws-amplify'

import {
  PageHeader,
  Row,
  Col,
  Button,
  Form,
  Input,
  Descriptions,
  Select,
  Card,
  Switch,
  Divider
} from 'antd'
import PhoneInput from 'react-phone-input-2'
import {PREFERRED_COUNTRIES} from 'consts'
import {filterOption} from '../../common'
import GooglePlacesAutocomplete, {geocodeByPlaceId,} from 'react-google-places-autocomplete'
import find from 'lodash/find'
import get from 'lodash/get'
import head from 'lodash/head'

import {
  get_country_by_name,
  get_country_value,
  get_street_type_value
} from '../../../../library/countries'
import { GOOGLE_API_KEY } from '../../../../library/settings.js'

import './Contact.css'
import {getStreetId} from './helpers'
import {showNotification} from 'utils'

const {Option} = Select

const getAddresses = async (geo, data_countries_list, streets) => {

  const place_id = get(geo, 'value.place_id')
  const geocode = await geocodeByPlaceId(place_id)
  const { address_components } = head(geocode) || {}

  const street_number = find(address_components, (component) => {
    return component.types.includes('street_number')
  })
  const street = find(address_components, (component) => {
    return component.types.includes('route')
  })

  const postal_code = find(address_components, (component) => {
    return component.types.includes('postal_code')
  })

  const administrative_area_level_1 = find(address_components, (component) => {
    return component.types.includes('administrative_area_level_1')
  })

  const sublocality_level_1 = find(address_components, (component) => {
    return component.types.includes('sublocality_level_1')
  })

  const country = find(address_components, (component) => {
    return component.types.includes('country')
  })

  const suburb = find(address_components, (component) => {
    return component.types.includes('locality')
  })

  const streetCountry = get_country_by_name(
    country.short_name,
    data_countries_list
  )

  try {
    const { streetStreetTypeId, routeType } = getStreetId(street, streets)
    return {
      ...(street && {streetStreetName: street.long_name.replace(routeType, ''),}),
      ...(street_number && { streetStreetNumber: street_number.long_name }),
      ...(postal_code && { streetPostcode: postal_code.long_name }),
      ...(sublocality_level_1 && { streetSuburb: sublocality_level_1.long_name }),
      ...(administrative_area_level_1 && {streetState: administrative_area_level_1.long_name,}),
      ...(suburb && { streetSuburb: suburb.long_name }),
      ...(streetCountry && { streetCountryId: streetCountry.id }),
      ...(streetStreetTypeId && { streetStreetTypeId: streetStreetTypeId }),
    }
  } catch (e) {
    console.log(e)
  }
}

const ClientContact = ({
  onPhoneInputChange,
  countriesList,
  streetCodes,
  clientRecord,
}) => {

  const [state, setState] = useState({
    streetStreetNumber: clientRecord?.streetStreetNumber,
    streetStreetName: clientRecord?.streetStreetName,
    streetStreetTypeId: clientRecord?.streetStreetTypeId,
    streetSuburb: clientRecord?.streetSuburb,
    streetState: clientRecord?.streetState,
    streetPostcode: clientRecord?.streetPostcode,
    streetCountryId: clientRecord?.streetCountryId,
    addressWestern_line1: clientRecord?.addressWestern_line1,
    addressWestern_suburb: clientRecord?.addressWestern_suburb,
    addressWestern_state: clientRecord?.addressWestern_state,
    addressWestern_postcode: clientRecord?.addressWestern_postcode,
    smsToNumber: '+' + clientRecord?.tel_mobile,
    smsToMessage: '',
    buttonLoading: false
  })
  const street_type_list = useSelector(
    (state) => state.leads?.streetCodes?.streetType
  )
  const [viewModeSwitchStatus, setViewModeSwitchStatus] = useState(true)

  const [form] = Form.useForm()

  const left = [
    {
      label: 'Telephone (home)',
      name: 'tel_home'
    },
    {
      label: 'Telephone (work)',
      name: 'tel_work'
    },
    {
      label: 'Telephone (mobile)',
      name: 'tel_mobile'
    }
  ]

  const handleSelectGoogleAddress = async (value) => {
    const addresses = await getAddresses(
      value,
      countriesList, //this.props.app_state.data_countries_list,
      street_type_list // this.state.data_street_type_list
    )

    setState({
      googleAddress: value,
      streetName_state: 'success',
      streetName_help: ' ',
      streetNumber_state: 'success',
      streetNumber_help: ' ',
      streetTypeId_state: 'success',
      streetTypeId_help: ' ',
      postcode_state: 'success',
      postcode_help: ' ',
      countryId_state: 'success',
      countryId_help: ' ',
      ...addresses,
    })
  }

  useEffect(() => {
    if (state.streetStreetNumber) {
      form.setFieldsValue({streetStreetNumber: state.streetStreetNumber})
      clientRecord['streetStreetNumber'] = state.streetStreetNumber
    }
    if (state.streetStreetName) {
      form.setFieldsValue({streetStreetName: state.streetStreetName})
      clientRecord['streetStreetName'] = state.streetStreetName
    }
    if (state.streetStreetTypeId) {
      form.setFieldsValue({streetStreetTypeId: state.streetStreetTypeId})
      clientRecord['streetStreetTypeId'] = state.streetStreetTypeId
    }
    if (state.streetSuburb) {
      form.setFieldsValue({streetSuburb: state.streetSuburb})
      clientRecord['streetSuburb'] = state.streetSuburb
    }
    if (state.streetState) {
      form.setFieldsValue({streetState: state.streetState})
      clientRecord['streetState'] = state.streetState
    }
    if (state.streetPostcode) {
      form.setFieldsValue({streetPostcode: state.streetPostcode})
      clientRecord['streetPostcode'] = state.streetPostcode
    }
    if (state.streetCountryId) {
      form.setFieldsValue({streetCountryId: state.streetCountryId})
      clientRecord['streetCountryId'] = state.streetCountryId
    }
    if (state.addressWestern_line1) {
      form.setFieldsValue({addressWestern_line1: state.addressWestern_line1})
      clientRecord['addressWestern_line1'] = state.addressWestern_line1
    }
    if (state.addressWestern_suburb) {
      form.setFieldsValue({addressWestern_suburb: state.addressWestern_suburb})
      clientRecord['addressWestern_suburb'] = state.addressWestern_suburb
    }
    if (state.addressWestern_state) {
      form.setFieldsValue({addressWestern_state: state.addressWestern_state})
      clientRecord['addressWestern_state'] = state.addressWestern_state
    }
    if (state.addressWestern_postcode) {
      form.setFieldsValue({addressWestern_postcode: state.addressWestern_postcode})
      clientRecord['addressWestern_postcode'] = state.addressWestern_postcode
    }
  }, [state])

  const toggleEdited = (viewModeSwitchStatus) => {
    setViewModeSwitchStatus(!viewModeSwitchStatus)
    // console.log('viewModeSwitchStatus', !viewModeSwitchStatus)
    if (!viewModeSwitchStatus){
      setState({
        ...state,
        streetStreetNumber: state.streetStreetNumber,
        streetStreetName: state.streetStreetName,
        streetStreetTypeId: state.streetStreetTypeId,
        streetSuburb: state.streetSuburb,
        streetState: state.streetState,
        streetPostcode: state.streetPostcode,
        streetCountryId: state.streetCountryId,
        addressWestern_line1: state.addressWestern_line1,
        addressWestern_suburb: state.addressWestern_suburb,
        addressWestern_state: state.addressWestern_state,
        addressWestern_postcode: state.addressWestern_postcode
      })
    }
  }

  const sendSms = () => {
    setState({
      ...state,
      buttonLoading: true
    })

    API.post('clients', '/sms', {
      body: {
        telephone: state?.smsToNumber, //"+61434213912",
        message: state?.smsToMessage //"Hello, this is a test message."
      }
    }).then(
      response => {

        if (!response?.payload) {
          throw new Error('payload is empty')
        } else {
          console.log(response.payload)
          showNotification('success', '', 'SMS send successful')
          setState({
            ...state,
            smsToNumber: '',
            smsToMessage: '',
            buttonLoading: false
          })
        }
      }
    ).catch(e => {
      console.log(e)
      showNotification ('error', '', 'SMS Failed to Send - Please advise SysAdmin')
      setState({
        ...state,
        buttonLoading: false
      })
    })
  }

  const onToNumberInputChange = (event) => {
    setState({
      ...state,
      smsToNumber: event.target.value
    })
  }

  const onToMessageInputChange = (event) => {
    setState({
      ...state,
      smsToMessage: event.target.value
    })
  }

  return (
    <>
      <Row gutter={[96, 24]}>
        <Col xs={8}>
          <Card title={<h3>Telephone</h3>} bordered={false}>
            {left.map(props => (
              <Form.Item key={props.label} {...props}>
                <PhoneInput
                  inputStyle={{width: '100%'}}
                  preferredCountries={PREFERRED_COUNTRIES}
                  masks={{au: '(.) ....-....'}}
                  enableSearch
                  disableCountryGuess={false}
                  onChange={onPhoneInputChange(props.name)}
                />
              </Form.Item>
            ))}
            <Input id='smsToNumber' placeholder='+xxxxxxxxxxx' value={state.smsToNumber} onChange={onToNumberInputChange}>
            </Input>
            <Input id='smsToMessage' placeholder='message' value={state.smsToMessage} onChange={onToMessageInputChange}>
            </Input>
            <Button loading={state?.buttonLoading} disabled={state?.smsToNumber?.charAt(0) !== '+' || !state?.smsToNumber || !state?.smsToMessage} onClick={() => sendSms()}>Send SMS</Button>
          </Card>
        </Col>
        <Col xs={16}>
          <Card title={<h3>Street Address</h3>} bordered={false} extra={
            <Switch
              checkedChildren='Edit'
              unCheckedChildren='View'
              id='keyEnabled'
              checked={!viewModeSwitchStatus}
              onChange={
                editMode => toggleEdited(editMode)}
            />
          }>
            {
              viewModeSwitchStatus && (!state?.streetStreetNumber || !state?.streetStreetNumber) && clientRecord &&
            <><Form.Item label='Full Address'>
              {clientRecord.streetUnitNumber ? clientRecord.streetUnitNumber + ' /' : ''} {clientRecord.streetStreetNumber} {' '}
              {clientRecord.streetStreetName} {clientRecord?.streetStreetTypeId ? street_type_list ? get_street_type_value(street_type_list, clientRecord.streetStreetTypeId) + ', ' : '' : ''} {clientRecord?.streetSuburb ? clientRecord.streetSuburb + ', ' : ''} {clientRecord?.streetSuburb ? clientRecord.streetState + ', ' : ''} {clientRecord.streetPostcode}
              {get_country_value(clientRecord.streetCountryId)}
            </Form.Item>
            <Form.Item label='Western Characters'>
              {clientRecord.addressWestern_line1} {' '}
              {clientRecord?.addressWestern_suburb ? clientRecord.addressWestern_suburb + ', ' : '' } {clientRecord?.addressWestern_state ? clientRecord.addressWestern_state + ', ' : ''} {clientRecord.addressWestern_postcode}
            </Form.Item></>
            }
            {
              // Use (state?.streetStreetNumber && state?.streetStreetNumber) as the condition to check if data address exist in state
              viewModeSwitchStatus && state?.streetStreetNumber && state?.streetStreetNumber &&
              <><Form.Item label='Full Address'>
                {state.streetUnitNumber ? state.streetUnitNumber + ' /' : ''} {state.streetStreetNumber} {' '}
                {state.streetStreetName} {state?.streetStreetTypeId ? street_type_list ? get_street_type_value(street_type_list, state.streetStreetTypeId) + ', ' : '' : ''} {state?.streetSuburb ? state.streetSuburb + ', ' : ''} {state?.streetSuburb ? state.streetState + ', ' : ''} {state.streetPostcode}
                {get_country_value(state.streetCountryId)}
              </Form.Item>
              <Form.Item label='Western Characters'>
                {state.addressWestern_line1} {' '}
                {state?.addressWestern_suburb ? state.addressWestern_suburb + ', ' : '' } {state?.addressWestern_state ? state.addressWestern_state + ', ' : ''} {state.addressWestern_postcode}
              </Form.Item></>
            }
            {
              !viewModeSwitchStatus &&
            <><Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={'Search Address Heading'}
                >
                  <div className="google-place">
                    <GooglePlacesAutocomplete
                      apiKey={GOOGLE_API_KEY}
                      selectProps={{
                        value: state.googleAddress,
                        onChange: handleSelectGoogleAddress,
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row><Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Unit Number' label='Unit Number' shouldUpdate>
                  <Input
                    id='streetUnitNumber'
                    value={state.streetUnitNumber}
                    onChange={(event) => {
                      form.setFieldsValue({ [event.target.id]: event.target.value })
                      clientRecord[event.target.id] = event.target.value
                      setState({
                        ...state,
                        streetUnitNumber: event.target.value
                      })
                    } }
                  />
                </Form.Item>
              </Col>
            </Row><Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Street Number' label='Street Number' shouldUpdate>
                  <Input
                    id='streetStreetNumber'
                    value={state.streetStreetNumber}
                    onChange={(event) => {
                      form.setFieldsValue({ [event.target.id]: event.target.value })
                      clientRecord[event.target.id] = event.target.value
                      setState({
                        ...state,
                        streetStreetNumber: event.target.value
                      })
                    } } />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item key='Street name' label='Street name' shouldUpdate>
                  <Input
                    id='streetStreetName'
                    value={state.streetStreetName}
                    onChange={(event) => {
                      form.setFieldsValue({ [event.target.id]: event.target.value })
                      clientRecord[event.target.id] = event.target.value
                      setState({
                        ...state,
                        streetStreetName: event.target.value
                      })
                    } } />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='Street Type' label='Street Type' shouldUpdate>
                  <Select
                    showSearch
                    value={state.streetStreetTypeId}
                    showArrow
                    placeholder='Select Street'
                    optionFilterProp='children'
                    filterOption={filterOption}
                    onChange={(value) => {
                      form.setFieldsValue({ 'streetStreetTypeId': value })
                      clientRecord['streetStreetTypeId'] = value
                      setState({
                        ...state,
                        streetStreetTypeId: value
                      })
                    } }
                  >
                    {streetCodes.streetType &&
                          streetCodes.streetType.map(x => (
                            <Option key={x.id} value={x.id}>
                              {x.fileBy}
                            </Option>
                          ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row><Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item key='Suburb' label='Suburb' shouldUpdate>
                  <Input
                    id='streetSuburb'
                    value={state.streetSuburb}
                    onChange={(event) => {
                      form.setFieldsValue({ [event.target.id]: event.target.value })
                      clientRecord[event.target.id] = event.target.value
                      setState({
                        ...state,
                        streetSuburb: event.target.value
                      })
                    } } />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='State' label='State' shouldUpdate>
                  <Input
                    id='streetState'
                    value={state.streetState}
                    onChange={(event) => {
                      form.setFieldsValue({ [event.target.id]: event.target.value })
                      clientRecord[event.target.id] = event.target.value
                      setState({
                        ...state,
                        streetState: event.target.value
                      })
                    } } />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='Post Code' label='Post Code' shouldUpdate>
                  <Input
                    id='streetPostcode'
                    value={state.streetPostcode}
                    onChange={(event) => {
                      form.setFieldsValue({ [event.target.id]: event.target.value })
                      clientRecord[event.target.id] = event.target.value
                      setState({
                        ...state,
                        streetPostcode: event.target.value
                      })
                    } } />
                </Form.Item>
              </Col>
            </Row><Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item key='Country' label='Country' shouldUpdate>
                  <Select
                    showSearch
                    value={state.streetCountryId}
                    showArrow
                    placeholder='Select Country'
                    optionFilterProp='children'
                    filterOption={filterOption}
                    onChange={(value) => {
                      form.setFieldsValue({ 'streetCountryId': value })
                      clientRecord['streetCountryId'] = value
                      setState({
                        ...state,
                        streetCountryId: value
                      })
                    } }
                  >
                    {countriesList.map(({ id, full_name }) => (
                      <Option key={id} value={id}>
                        {full_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <Row gutter={[8, 0]}>
              <Col xs={24}>
                <h5>Western Character Address</h5>
              </Col>
              <Col xs={24}>
                <Form.Item label='Address' shouldUpdate>
                  <Input
                    id='addressWestern_line1'
                    value={state.addressWestern_line1}
                    onChange={(event) => {
                      form.setFieldsValue({ [event.target.id]: event.target.value })
                      clientRecord[event.target.id] = event.target.value
                      setState({
                        ...state,
                        addressWestern_line1: event.target.value
                      })
                    } }
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Row gutter={[8, 0]}>
              
                  <Col xs={12}>
                    <Form.Item label='Suburb' shouldUpdate>
                      <Input
                        id='addressWestern_suburb'
                        value={state.addressWestern_suburb}
                        onChange={(event) => {
                          form.setFieldsValue({ [event.target.id]: event.target.value })
                          clientRecord[event.target.id] = event.target.value
                          setState({
                            ...state,
                            addressWestern_suburb: event.target.value
                          })
                        } }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item label='State' shouldUpdate>
                      <Input
                        id='addressWestern_state'
                        value={state.addressWestern_state}
                        onChange={(event) => {
                          form.setFieldsValue({ [event.target.id]: event.target.value })
                          clientRecord[event.target.id] = event.target.value
                          setState({
                            ...state,
                            addressWestern_state: event.target.value
                          })
                        } }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item label='Postcode' shouldUpdate>
                      <Input
                        id='addressWestern_postcode'
                        value={state.addressWestern_postcode}
                        onChange={(event) => {
                          form.setFieldsValue({ [event.target.id]: event.target.value })
                          clientRecord[event.target.id] = event.target.value
                          setState({
                            ...state,
                            addressWestern_postcode: event.target.value
                          })
                        } }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            </>
            }
          </Card>
        </Col>
      </Row>
      <Row gutter={[96, 24]}>
        <Col xs={8}>
          <Card title={<h3>Email</h3>} bordered={false}>
            <Form.Item shouldUpdate>
              {({getFieldsValue}) => {
                const {email} = getFieldsValue(['email'])
                return (
                  <Descriptions
                    bordered
                    layout='vertical'
                    size='small'
                    column={{
                      xs: 1,
                      sm: 1
                    }}
                  >
                    <Descriptions.Item label='E-mail'>{email}</Descriptions.Item>
                    <Descriptions.Item label='E-mail secondary'>
                      <Form.Item name='email_secondary' shouldUpdate>
                        <Input />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                )
              }}
            </Form.Item>
          </Card>
        </Col>
        <Col xs={16}>
          <Card title={<h3>Postal Address</h3>} bordered={false}>
            <Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Box Number' label='Box Number' name='postalBoxNumber' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='Box Type' label='Box Type' name='postalBoxTypeId' shouldUpdate>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Box'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {streetCodes.postalType &&
                      streetCodes.postalType.map(x => (
                        <Option key={x.id} value={x.id}>
                          {x.fileBy}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col xs={6}>
                <Form.Item key='Street Number' label='Street Number' name='postalStreetNumber' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item key='Street Name' label='Street Name' name='postalStreetName' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item label='Street Type' key='postalStreetTypeId' name='postalStreetTypeId' shouldUpdate>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Street'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {streetCodes.streetType &&
                      streetCodes.streetType.map((x) => (
                        <Option key={x.id} value={x.id}>
                          {x.fileBy}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item key='Suburb' label='Suburb' name='postalSuburb' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='State' label='State' name='postalState' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item key='postcode' label='postcode' name='postalPostcode' shouldUpdate>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col xs={12}>
                <Form.Item label='Country' name='postalCountryId'>
                  <Select
                    showSearch
                    showArrow
                    placeholder='Select Country'
                    optionFilterProp='children'
                    filterOption={filterOption}
                  >
                    {countriesList.map(({id, full_name}) => (
                      <Option key={id} value={id}>
                        {full_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const ClientContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientContact)

export default ClientContactContainer