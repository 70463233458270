import * as React from 'react'
import {
  GatMoney,
  TraderEvolution
} from './components'
import {Client} from 'types'

interface PlatformProps {
  gatMoneyClient: boolean;
  clientRecord: Client;
}

// TODO Refactor GatMoney & TraderEvolution

const Platform: React.FC<PlatformProps> = ({gatMoneyClient, clientRecord}) => {
  if (gatMoneyClient) {
    return <GatMoney clientRecord={clientRecord} />
  }

  return <TraderEvolution clientRecord={clientRecord} />
}

export default Platform
