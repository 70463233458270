import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select
} from 'redux-saga/effects'

import {
  osName,
  fullBrowserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  engineName,
  engineVersion,
} from 'react-device-detect'
import {notification} from 'antd'
import {getStaffList} from '../staffs'
import {clientsActions} from '../clients'
import {settingsActions} from '../settings'
import {
  API,
  Auth
} from 'aws-amplify'

import * as types from './types'
import {
  appStateSelectors,
  appStateActions
} from '.'

import {openNotificationWithIcon} from '../../utils'

const requests = [
  {
    apiName: 'countries',
    path: '/list',
    errorMessage: 'Failed to fetch countries'
  },
  {
    apiName: 'accounts',
    path: '/list',
    errorMessage: 'Failed to fetch accounts'
  },
  {
    apiName: 'accounts',
    path: '/getAccountTypelist',
    errorMessage: 'Failed to fetch accounts types list'
  },
  {
    apiName: 'status-types',
    path: '/list',
    errorMessage: 'Failed to fetch status types'
  },
  {
    apiName: 'clients',
    path: '/getAdminClientTypes',
    errorMessage: 'Failed to fetch client types list'
  },
  {
    apiName: 'clients',
    path: '/getAdminCompanyTypes',
    errorMessage: 'Failed to fetch company types list'
  },
  {
    apiName: 'clients',
    path: '/get-gender-list',
    errorMessage: 'Failed to fetch gender list'
  },
  {
    apiName: 'clients',
    path: '/get-emp-status-list',
    errorMessage: 'Failed to fetch employment status list'
  },
  {
    apiName: 'clients',
    path: '/getAdminClientCategories',
    errorMessage: 'Failed to fetch client types list'
  },
  {
    apiName: 'clients',
    path: '/get-sic-divisions',
    errorMessage: 'Failed to fetch sic divisions list'
  },
  {
    apiName: 'clients',
    path: '/get-sic-divisions-groups-list',
    errorMessage: 'Failed to fetch the whole sic divisions list'
  },
  {
    apiName: 'leads',
    path: '/getStreetTypes',
    errorMessage: 'Failed to fetch street types list'
  }
]

function* fetchAppData(accountID) {
  try {
    class FetchError extends Error {
      constructor(message, header) {
        super(message)
        this.header = header
      }
    }
    const promises = requests.map(function* ({
      apiName,
      path,
      errorMessage
    }) {
      try {
        const {payload} = yield call([API, API.get], apiName, path)
        return payload
      } catch (e) {
        throw new FetchError(e.message, errorMessage)
      }
    })
    const [
      countriesList,
      accountsList,
      accountTypeList,
      clientStatusList,
      clientTypeList,
      companyTypeList,
      genderList,
      employmentStatusList,
      clientCategoriesList,
      sicDivisions,
      sicGroups,
      streetCodes,
      statusIndicators,
      {
        natureOfTransactions,
        expectedTransactionsCount,
        purposeOfAccount,
        sourceOfWealth,
        noTfnReasons,
      },
      {payload: tradingPlatformAccounts},
      {payload: currencies},
    ] = yield all([
      ...promises,
      call([API, API.get], 'system', '/status-indicators'),
      call([API, API.get], 'client-financial-info', '/data'),
      call([API, API.get], 'trading-platforms', `/accounts/list/account/${accountID}`),
      call([API, API.get], 'currencies', '/list')
    ])

    yield call(getStaffList, {currentAccountID: accountID})
    yield put(clientsActions.fetchClients())
    yield put(settingsActions.getTradingPlatformsList(accountID))

    yield call(getUserGeoData)

    return {
      countriesList,
      accountsList,
      accountTypeList,
      clientStatusList,
      clientTypeList,
      companyTypeList,
      genderList,
      employmentStatusList,
      clientCategoriesList,
      sicDivisions,
      sicGroups,
      streetCodes,
      statusIndicators,

      natureOfTransactions,
      expectedTransactionsCount,
      purposeOfAccount,
      sourceOfWealth,
      noTfnReasons,
      tradingPlatformAccounts,
      currencies
    }
  } catch (e) {
    openNotificationWithIcon('error', e.header, e.message)
    return {}
  }
}

function* switchStaff(
  {
    payload: {
      id,
      account_id,
      user_id
    }
  }
) {
  try {
    const {payload} = yield call([API, API.get], 'staff', `/get/id/${id}`)
    yield put({
      type: types.SWITCH_STAFF_SUCCESS,
      payload
    })

    yield put(appStateActions.switchAccount(account_id, user_id))

    yield call([API, API.put], 'user-staff', `/update/${user_id}`, {body: {lastActiveStaffId: id}})
  } catch (e) {
    openNotificationWithIcon('error', 'Failed to change staff', e.message)
    yield put({type: types.SWITCH_STAFF_ERROR})
  }
}
function* switchAccount(action) {
  try {
    const {
      payload: {
        userID,
        accountID
      }
    } = action

    const {accountsList} = yield select(appStateSelectors.stateSelector)

    const resp = yield call(fetchAppData, accountID)

    yield put({
      type: types.SWITCH_ACCOUNT_SUCCESS,
      payload: {
        ...resp,
        loading: false,
        currentAccountRecord: accountsList.find(({id}) => id === accountID)
      }
    })

    yield put({type: types.RE_MOUNT_APP})

    yield call([API, API.put], 'user-staff', `/update/${userID}`, {body: {last_active_account: accountID}})

    yield put({
      type: types.UPDATE_USER_LAST_ACTIVE_ACCOUNT,
      payload: accountID
    })
  } catch (e) {
    openNotificationWithIcon('error', 'Failed to set last active account', e.message)
  }
}

function* handleFetchData({payload, type}) {
  const authAction = type === types.AUTHENTICATE_USER_REQUEST

  try {
    let cognitoUser
    
    if (authAction) {
      cognitoUser = yield call([Auth, Auth.currentAuthenticatedUser])
    } else {
      cognitoUser = yield call([Auth, Auth.signIn], payload.email, payload.password)
    }

    const {
      payload: {
        staff,
        user_staff,
        staff_access
      }
    } = yield call([API, API.get],
      'user-staff',
      `/get/aws-cognito/${cognitoUser.getUsername()}`
    )
    const appData = yield call(fetchAppData, user_staff.last_active_account || staff.account_id)

    logLogin(cognitoUser)

    yield put({
      type: types.AUTHENTICATE_USER_SUCCESS,
      payload: {
        user: cognitoUser,
        ...appData,
        currentStaffRecord: staff,
        currentUserRecord: user_staff,
        staffAccess: staff_access,
      }
    })
  } catch (e) {
    if (authAction) {
      yield put({type: types.AUTHENTICATE_USER_ERROR})
    } else {
      let message = e.message

      yield put({type: types.LOG_OUT_USER_ERROR})

      if (e.code === 'UserNotConfirmedException') {
        message = 'User is not confirmed.'
      }

      if (e.code === 'NotAuthorizedException') {
        message = 'Incorrect username or password'
      }

      notification.warning({message})
    }
  }
}

async function logLogin(user) {
  if (user.username) {
    return API.post('logging', '/create', {
      body: {
        log_type: 1,
        memo: `User ${user.username} Login Success`,
        logData: `{"userName": "${user.username}", "email": "${user.attributes.email}", "osName": "${osName}", "fullBrowserVersion": "${fullBrowserVersion}", "browserName": "${browserName}", "mobileVendor": "${mobileVendor}", "mobileModel": "${mobileModel}", "engineName": "${engineName}", "engineVersion": "${engineVersion}"}`
      },
    })
      .then((response) => {
        // console.log(response.payload);
        return response.payload
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

function* getUserGeoData() {

  // function for getting user country location
  let userGeo = ''
  const geolocationDb = apiKey =>
    fetch(`https://geolocation-db.com/json/${apiKey}`, { mode: 'cors' })
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error(`Request error. ${res.status}`)
      })
      .then(res => {
        if (res.country_code) {
          userGeo = res
        }
      })
      .catch(e => {
        throw new Error(`Cannot fetch country from Geolocation DB. ${e}`)
      })

  try {
    let result
    yield result = geolocationDb('ef6c41a0-9d3c-11eb-8f3b-e1f5536499e7')

    yield put({
      type: types.GET_CURRENT_COUNTRY_SUCCESS,
      payload: userGeo
    })

  } catch (e) {
    openNotificationWithIcon(
      'error',
      'Failed to get countries',
      e.message
    )
    put({ type: types.GET_CURRENT_COUNTRY_ERROR })
  }
}

function* logOutUser() {
  try {
    yield call([Auth, Auth.signOut])
    yield put({type: types.LOG_OUT_USER_SUCCESS})
  } catch (e) {
    openNotificationWithIcon('error', 'An error occurred', e.message)
    yield put({type: types.LOG_OUT_USER_ERROR})
  }
}

export default function* saga() {
  yield all([
    yield takeEvery(types.SWITCH_STAFF_REQUEST, switchStaff),
    yield takeEvery(types.SWITCH_ACCOUNT_REQUEST, switchAccount),
    yield takeEvery(types.GET_CURRENT_COUNTRY_REQUEST, getUserGeoData),
    yield takeLatest([types.AUTHENTICATE_USER_REQUEST, types.LOGIN_USER_REQUEST], handleFetchData),
    yield takeEvery(types.LOG_OUT_USER_REQUEST, logOutUser),
  ])
}
