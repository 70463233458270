import React from 'react'
import {
  Row,
  Col,
  Input,
  Form,
  Divider,
  InputDatePicker
} from 'components'

import {columnSize} from '../../../common'
import moment from 'moment'

const TraderEvolutionAgreement = () => {

  return (
    <Row gutter={[16, 24]}>
      <Col {...columnSize}>
        <Row gutter={[8, 24]}>
          <Col span={12}>
            <Form.Item
              label='W8 Ben Agreed'
              name='agreed_w8ben'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_w8benDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_w8benNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[8, 24]}>
          <Col span={12}>
            <Form.Item
              label='FSG Agreed'
              name='agreed_fsg'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_fsgDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_fsgNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[8, 24]}>
          <Col span={12}>
            <Form.Item
              label='PDS Agreed'
              name='agreed_pds'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_pdsDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_pdsNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

      </Col>

      <Col {...columnSize}>

        <Row gutter={[8, 24]}>
          <Col span={12}>
            <Form.Item
              label='Terms & Conditions Agreed'
              name='agreed_terms'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_termsDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_termsNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[8, 24]}>
          <Col span={12}>
            <Form.Item
              label='Privacy Policy Agreed'
              name='agreed_privacy'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='agreed_privacyDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='agreed_privacyNotes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[8, 24]}>
          <Col span={12}>
            <Form.Item
              label='Signed Agreement'
              name='signed_agreement_datetime'
              getValueProps={value => ({value: value ? moment(value, 'YYYY-MM-DD HH:mm:ss') : ''})}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputDatePicker
              allowClear
              name='signed_agreementDate'
              label='client.agreements.adminOverride'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Notes' name='signed_agreement_notes'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default TraderEvolutionAgreement